header {
    padding: 5px 0;
    background-image: url('../../assets/images/bgHeader.jpg');
    background-size: cover;
    background-position: center center;
    -webkit-transition: all 0.5s linear;
    -moz-transition: all 0.5s linear;
    -o-transition: all 0.5s linear;
    transition: all 0.5s linear;
    margin-bottom: 25px;
}

#headerBox > a {
    display: flex;
    max-width: 50%;
} 

#logo {
    width: 100%;
    max-width: 141px;
}

.header-col-center {
    margin-top: 15px;
}


.header-col-center h4 {
    color: #fff;
    padding: 15px 26px;
    margin-top: -22px;
    border-radius: 0 0 20px 20px;
    background-color: #ed1c24;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 18px;
    text-align: center;
}

#mainMenu {
    padding: 0;
    margin: 20px 0 0 0;
    display: flex;
    list-style: 
    none;
}

#mainMenu li {
    position: relative;
}

#mainMenu li:not(:last-child) {
    margin-right: 28px;
}

#mainMenu li a {
    font-size: 14px;
    text-transform: uppercase;
    color: #FFF;
}

#headerBox {
    display: flex;
    justify-content: space-between;
}

.mainMenuMobile {
    display: none;
}

@media(max-width: 1199px){
    #mainMenu {
        padding: 20px 0 0;
        background: #fff;
        position: fixed;
        left: 0;
        right: 0;
        bottom: 0;
        top: 120px;
        z-index: 99;
        padding-left: 15px;
        overflow-y: auto;
    }
    .mainMenuMobile {
        display: block;
    }
    #mainMenu.show {
        display: block;
    }
    #mainMenu.hide {
        display: none;
    }
    #mainMenu li a {
        height: 70px;
        display: flex;
        align-items: center;
        border-bottom: solid 1px #cbcccd;
        color: #000;
    }    
    #mainMenu li {
        margin-right: 28px;
    }
    #headerBox {
        justify-content: center;
        flex-direction: column;
        padding-top: 30px;
    }
    header.fixed #headerBox {
        padding-top: 0;
    }
    #searchBox {
        width: 100%;
        margin-top: 15px;
    }
    img#logo {
        margin-bottom: 15px;
    }
}

button.openMenu {
    background: transparent;
    border: none;
    outline: none;
    position: absolute;
    top: 55px;
    right: 15px;
    display: none;
}

.rowToggle {
    width: 26px;
    height: 3px;
    margin: 5px 0;
    background: #FFF;
    border-radius: 2px;
}

@media screen and (max-width: 1199px) {
    button.openMenu {
        display: block;
    }
    #iconSearch {
        display: none;
    }
}

header.fixed {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background-color: #fff;
    z-index: 999;
    box-shadow: 0px 7px 20px 0px rgb(0 0 0 / 19%);
    background-image: none;
    color: #ccc;
}

header.fixed #headerBox {
    align-items: center;
}

header.fixed #logo {
    max-width: 100px;
}

header.fixed .header-col-center {
    margin-top: 0;
}

header.fixed ul#mainMenu {
    margin: 0;
}

header.fixed #mainMenu li a {
    color: #f15e22;
}

@media(max-width: 1199px){
    header.fixed #searchBox {
        display: none;
    }
    header.fixed img#logo {
        margin-bottom: 0;
    }
    header.fixed button.openMenu {
        top: 17px;
    }
    header.fixed .rowToggle {
        background: #f15e22;
    }
    header.fixed #mainMenu {
        top: 76px;
    }
}

#mainSubmenu.hide {
    display: none;
}

@media(max-width: 1199px){
    .header-col-center {
        margin-top: 15px;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        width: 200px;
        margin: 15px auto 0;
    }

    .header-col-center h4 {
        padding: 10px 20px;
        font-size: 12px;
    }

    header.fixed .header-col-center {
        position: absolute;
        bottom: -47px;
        top: auto;
    }

    #mainSubmenu.show {
        display: flex;
    }    
}

#mainSubmenu {
    flex-direction: column;
}

#mainSubmenu ul {
    list-style: none;
    padding: 0;
}

#mainSubmenu ul li {
    white-space: nowrap;
}

#mainSubmenu ul li a {
    text-transform: none;
    color: #000;
}

#mainSubmenu ul li:first-child {
    font-weight: 700;
}

#mainSubmenu ul li:not(:first-child) a {
    font-weight: 400;
}

@media(min-width: 992px){
    #mainMenu > li:hover > #mainSubmenu {
        display: flex;
    }

    #mainSubmenu {
        flex-direction: row;
        background: #fff;
        position: absolute;
        right: -5px;
        top: 52px;
        z-index: 999;
        padding: 20px 0;
        box-shadow: 0px 19px 30px 0px rgb(0 0 0 / 12%);
        border-radius: 0 0 5px 5px;
    }

    #mainSubmenu {
        top: 65px;
    }

    header.fixed #mainSubmenu {
        top: 50px;
    }

    .fixed #mainSubmenu {
        top: 75px;
    }

    #mainSubmenu ul {
        margin: 0 30px;
        padding: 0;
    }

    #mainSubmenu ul li {
        white-space: nowrap;
        margin: 8px 0;
    }

    #mainSubmenu > ul > li a {
        min-height: auto;
    }
}