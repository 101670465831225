.borderLeft {
    height: 55%;
    width: 4px;
    background: #f15e22;
    position: absolute;
    top: 20%;
    left: -2px;
    transition: .3s;
}

.borderLeft.hide {
    visibility: hidden;
}

.newsBox:hover .borderLeft {
    display: block;
    top: 10%;
    height: 75%;
}

.newsBox {
    margin-bottom: 20px;
    border: 1px solid #c8c8c8;
    box-shadow: 0 0 15px rgb(0 0 0 / 20%);
    position: relative;
    background: #FFF;
    transition: .3s;
}

.newsBox:hover {
    box-shadow: 0 0 25px rgb(0 0 0 / 40%);
    border: 1px solid #f15e22;
}

.newsImg {
    object-fit: cover;
    max-width: 100%;
    width: 100%;
}

.newsTitle {
    color: #f15e22;
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 15px;
    transition: all 0.3s ease-in-out 0s;
}

.newsBox.featured .newsTitle {
    font-size: 26px;
}

.newsDesc {
    color: #000;
    font-size: 14px;
    line-height: 22px;
    margin: 0 0px 20px;
}
