.newsVertical {
    margin-bottom: 25px;
}

.newsVerticalTitle {
    color: #000;
    font-weight: 600;
    font-size: 18px;
    transition: .3s;
}

.newsVertical:hover .newsVerticalTitle {
    color: #f15e22;
}

.newsVerticalImg {
    width: 100%;
    height: 220px;
    object-fit: cover;
}

.infoBoxVertical {
    margin: 10px 0 5px;
}

.infoBoxVertical ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.infoBoxVertical button {
    background-color: transparent;
    padding: 0;
    text-transform: uppercase;
    color: #f15e22;
    border: none;
    font-size: 14px;
}