.box-search {
    width: 100%;
    position: relative;
}

.box-search input {
    width: 100%;
    height: 50px;
    padding: 15px;
    border: solid 1px #CCC;
    margin-bottom: 25px;
}

.box-search button {
    position: absolute;
    top: 0;
    right: 0;
    height: 50px;
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f15e22;
    border: none;
    color: #FFF;
    font-size: 26px;
}