div#revenues {
    margin-bottom: 40px;
}

.boxRevenue {
    border: 1px solid #cecece;
    border-radius: 2px;
    box-shadow: 0px 0px 12px rgb(0 0 0 / 28%);
}

.boxRevenue img {
    width: 100%;
    height: 450px;
    object-fit: cover;
}

.aboutRevenue {
    padding: 20px;
    height: 450px;
    overflow-y: scroll;
}

.titleRevenue {
    color: #f15e22;
    font-weight: 700;
    margin-bottom: 20px;
}

.aboutRevenue ul {
    padding-inline-start: 20px;
}

.aboutRevenue ul li {
    margin: 15px 0;
}

div#btns {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
}

.btnsRevenue {
    background: #fff;
    height: 40px;
    width: 175px;
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 700;
    outline: none;
}

button#btnPrev {
    border: 2px solid #3b3b3b;
    color: #3b3b3b;
}

button#btnNext {
    border: 2px solid #f15e22;
    color: #f15e22;
    margin-left: 10px;
}

.btnsRevenue.disabled {
    opacity: 0.1;
}

@media screen and (max-width: 768px) {
    .aboutRevenue {
        height: 190px;
    }
    div#btns {
        justify-content: center;
    }
}

@media screen and (max-width: 600px) {
    .aboutRevenue {
        height: 420px;
    }
}

@media screen and (max-width: 375px) {
    .btnsRevenue {
        font-size: 15px;
    }
}

@media screen and (max-width: 320px) {
    .btnsRevenue {
        font-size: 13px;
    }
}