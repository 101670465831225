.contentGallery {
    font-size: 14px;
    border: 1px solid #ccc;
    border-top: none;
    padding: 8px 15px 0;
    border-radius: 0 0 2px 2px;
}

.contentGallery b {
    color: #3b3b3b;
    font-weight: 700;
}

.contentGallery.contentPost {
    padding-top: 15px;
    background: #FFF;
}

p.postText {
    font-size: 13px;
    margin-top: 10px;
}

.media-link {
    color: #000;
}

.media-link:hover {
    color: #f15e22;
}

@media screen and (max-width: 768px) {
    .slick-prev, .slick-next {
        display: none !important;
    }
}