.contentCalendar {
    padding-top: 30px;
    padding-bottom: 30px;
    border: solid 3px #f15e22;
}

.boxScroll {
    overflow-y: scroll;
    height: calc(100% - 71px);
}

.dateSchedule {
    position: relative;
    display: flex;
    justify-content: center;
    padding-bottom: 5px;
    padding-right: 15px;
    margin-bottom: 20px;
}

.dateSchedule button {
    height: 50px;
    padding-left: 25px;
    padding-right: 25px;
    margin: 0 10px;
    background-color: #CCC;
    border: none;
    color: #FFF;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 14px;
}

.dateSchedule button.active {
    background-color: #f15e22;
}

.dateSchedule .showCalendar {
    position: absolute;
    right: 0;
    color: #f15e22;
    font-weight: 700;
    text-decoration: none;
    background: transparent;
    border: none;
    font-size: 13px;
}

.dateSchedule .showCalendar svg {
    font-size: 23px;
    margin-right: 5px;
    margin-top: -5px;
}

.noEvents {
    text-align: center;
    padding-top: 20px;
}

.boxSchedule {
    display: flex !important;
    padding: 0 15px;
    min-height: 70px;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
}

.boxSchedule p, .boxSchedule p strong {
    margin-bottom: 0;
    font-size: 14px !important;
}

p.hour {
    font-size: 13px;
    font-weight: 900;
    color: #f15e22;
}

p.subject {
    color: #000;
    font-weight: 600;
    font-size: 14px;
}

p.place {
    font-size: 14px;
}

p.description {
    font-size: 14px;
    margin-top: 15px;
}
.contentEvents {
    width: calc(100% - 100px);
    margin: 0 auto;
}


.contentEvents .slick-next {
    display: block !important;
    content: none;
    width: 0;
    height: 0;
    border-top: 20px solid transparent;
    border-bottom: 20px solid transparent;
    border-left: 20px solid #f15e22;
    position: absolute;
    right: -30px;
}

.contentEvents .slick-prev {
    display: block !important;
    content: none;
    width: 0;
    height: 0;
    border-top: 20px solid transparent;
    border-bottom: 20px solid transparent;
    border-right: 20px solid #f15e22;
    position: absolute;
    left: -30px;
}

.contentEvents .slick-track .slick-slide {
    border-left: solid 3px #f15e22;
}

.contentEvents .slick-track .slick-slide:first-child, .contentEvents .slick-slide.slick-current {
    border-left: none;
}

.contentEvents .slick-next.slick-disabled, .contentEvents .slick-prev.slick-disabled {
    opacity: .2;
}


.contentEvents .slick-next:before, .contentEvents .slick-prev:before {
    content: none;
}

@media(max-width: 991px){
    .showCalendar {
        display: none;
    }   
}