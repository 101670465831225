.slick-prev {
    left: 10px;
}

.slick-next {
    right: 10px;
}

.slick-prev, .slick-next, .slick-prev:hover, .slick-next:hover {
    color: #f15e22;
    z-index: 9;
}

.contentSlider {
    position: relative;
    display: flex !important;
    justify-content: center;
    flex-direction: row-reverse;
}

.newsSlider {
    width: 33%;
    margin-top: 40px;
}

.boxSlider {
    width: 60%;
    margin-left: -6%;
    margin-bottom: 20px;
}

.boxSlider .newsContent {
    padding-left: 60px;
    margin-top: 5px;
}

.sliderRight img {
    width: 100%;
    max-width: 100%;
    object-fit: cover;
    height: 500px;
}

.boxSlider .infoBox {
    margin: 5px 0;
}

.slick-dots {
    position: absolute;
    text-align: right;
    right: 45px;
}

.slick-dots li {
    margin: 0 10px;
    width: auto;
    height: auto;
}

.slick-dots li:first-child {
    margin: 0 10px 0 0;
}

.slick-dots li button {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    opacity: .25;
    background-color: #e30613;
}

.slick-dots li.slick-active button {
    opacity: 1;
    background-color: #e30613;
    border: 1px solid #f2642a;
}

.slick-dots li button:before {
    font-size: 0;
}

@media(max-width: 1199px){
    .contentSlider {
        flex-direction: column;
    }
    .newsSlider {
        width: 100%;
        margin-top: 0;
    }
    .contentSlider .newsBox {
        border: none;
        box-shadow: none;
    }
    .contentSlider .newsBox .newsContent {
        padding: 0;
    }
    .contentSlider .newsBox .borderLeft {
        display: none;
    }
    .boxSlider {
        width: 100%;
        margin-left: 0;
    }
    .sliderRight img{
        width: 100%;
    }
}

@media(max-width: 768px) {
    .contentSlider {
        display: initial !important;
    }
    .sliderRight img {
        height: 300px;
    }
    .boxSlider {
        width: 100%;
        margin-left: 0;
    }
    .boxSlider .newsContent {
        padding-left: 0;
        text-align: center;
        margin-top: 0;
    }
    .newsSlider {
        width: 100%;
        margin-top: 0;
    }
    .slick-dots {
        position: absolute;
        text-align: center;
        right: 0;
        bottom: -15px;
    }
}