.page-gallery .imgGallery img {
    width: 100%;
    height: 280px;
    object-fit: cover;
}

.page-gallery .sliderGallery {
    margin-bottom: 40px;
}

.page-gallery-content {
    margin: 0 -7px;
}