#pageMenu {
    padding: 0;
    margin: 10px 0 15px 0;
    display: flex;
    list-style: none;
    justify-content: flex-end;
}

#pageMenu li:not(:last-child) {
    margin-right: 28px;
}

#pageMenu li a {
    font-size: 14px;
    color: #f15e22;
    font-weight: 600;
}

.sectionMidias {
    background: #F2F2F2;
    margin: 50px 0 25px;
    padding-bottom: 50px;
}

.otherNewsBox {
    margin-bottom: -25px;
}

@media screen and (max-width: 768px) {
    #pageMenu {
        display: none;
    }
}