.newsSingleImage {
    width: 100%;
    margin: 20px 0;
}

.boxTopNotice {
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
}

.boxTopNotice a, .boxTopNotice span {
    font-weight: 700;
    color: #3b3b3b;
    font-size: 16px;
}

.newsSingleContent img, .newsSingleContent iframe {
    max-width: 100%;
}

.singleNewsTags {
    display: flex;
    flex-wrap: wrap;
    padding: 15px 0;
    overflow: hidden;
}

.singleNewsTags a {
    text-align: center;
    flex: 1;
    font-weight: 700;
    color: #fff;
    font-size: 14px;
    margin-right: 15px;
    margin-bottom: 15px;
    background-color: #f15e22;
    padding: 8px 15px;
    border-radius: 17px;
    white-space: nowrap;
}

.content {
    max-width: 900px;
    margin: 0 auto;
}

.newsSingleTitle {
    color: #f15e22;
    font-weight: 700;
    font-size: 32px;
    text-align: center;
}

.newsSingleSubtitle {
    font-weight: 400;
    font-size: 24px;
    margin-bottom: 25px;
    text-align: center;
}

.titleMoreNews {
    font-weight: 700;
    font-size: 18px;
    margin-bottom: 25px;
    color: #3b3b3b;
}