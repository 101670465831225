.modal-filter {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgb(0 0 0 / 80%);
    z-index: 9999;
    display: flex;
    align-items: center;
    justify-content: center;
}

.modal-filter-content {
    width: 700px;
    max-width: 80%;
    background-color: #fff;
    overflow: hidden;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
}

.modal-filter-header {
    background-color: #ed1c24;
    padding: 15px 60px 15px 15px;
    position: relative;
    display: flex;
    align-items: center;
}

.modal-filter-header h3 {
    color: #FFF;
    font-weight: 700;
    font-size: 22px;
    margin-bottom: 0;
}

.modal-filter-body {
    padding: 20px 20px 0;
    overflow-y: auto;
    max-height: 70vh;
}

.btn-close {
    position: absolute;
    right: 0;
    top: 0;
    height: 56px;
    width: 56px;
    font-size: 28px;
    background: transparent;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
}

.modal-filter .row a {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px;
    border: solid 1px #f15e22;
    background-color: #ed5c22;
    color: #fff;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 14px;
    text-align: center;
    margin-bottom: 20px;
    height: calc(100% - 25px);
}