footer {
    background: #f15e22;
    color: #fff;
    padding: 15px 0;
    margin-top: 30px;
}

footer .container {
    display: flex;
    justify-content: space-between;
}

footer p {
    margin-bottom: 0;
}