@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;700&display=swap');

.infinite-scroll-component {
    overflow-x: hidden !important;
}

h1, h2, h3, h4, h5, h6, p, a, button, input, textarea, a, li {
    font-family: 'Montserrat', sans-serif;
}

input, button, textarea {
    outline: none;
}

.container {
    position: relative;
}

a {
    transition: .2s;
}

a:hover {
    text-decoration: none;
}

@media (min-width: 1200px){
    .container, .container-lg, .container-md, .container-sm, .container-xl {
        max-width: 2000px;
    }
}

@media(min-width: 576px){
    .container, .container-fluid, .container-lg, .container-md, .container-sm, .container-xl {
        padding-right: 25px;
        padding-left: 25px;
    }
}

.infoBox {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.infoBox button {
    background: #f15e22;
    border: none;
    color: #FFF;
    font-size: 13px;
    text-transform: uppercase;
    padding: 5px 10px;
}

.infoBox ul {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    align-items: center;
}

.infoBox ul li:not(:last-child) {
    margin-right: 10px;
}

.infoBox ul li a, .infoBox ul li {
    font-weight: 400;
    color: #000;
    font-size: 14px;
}

.infoBox ul li p {
    margin-bottom: 0;
    margin-top: 0;
}

.infoBoxSmaller {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.infoBoxSmaller button {
    background: #f15e22;
    border: none;
    color: #FFF;
    font-size: 10px;
    text-transform: uppercase;
    height: 29px;
}

.infoBoxSmaller ul {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
}

.infoBoxSmaller ul li:not(:last-child) {
    margin-right: 10px;
}

.infoBoxSmaller ul li a, .infoBoxSmaller ul li {
    font-weight: 400;
    color: #000;
    font-size: 14px;
}

.infoBoxSmaller ul li p {
    margin-bottom: 0;
    margin-top: 5px;
    font-size: 12px;
}

.pageTitle {
    color: #f15e22;
    font-weight: 700;
    margin-bottom: 20px;
    margin-top: 20px;
}

.titleCategories {
    list-style: none;
    margin: 25px 0 25px;
    padding: 0;
    display: flex;
    float: left;
}

.titleCategories li {
    text-transform: uppercase;
    color: #3b3b3b;
    font-size: 18px;
    transition: .2s;
}

.titleCategories li:first-child {
    font-size: 14px;
}

.titleCategories li.active {
    font-weight: 700;
    color: rgb(255, 0, 0);
}

.titleCategories li svg {
    color: #f15e22;
    font-size: 22px;
    margin-top: -3px;
}

.titleCategories li:not(:last-child) {
    margin-right: 25px;
}

.titleCategories li:not(:first-child) {
    cursor: pointer;
}

#titleFilter.titleCategories li svg {
    color: #f15e22;
    font-size: 25px;
    margin-top: 0px;
}

#titleFilter.titleCategories li {
    text-transform: initial;
    color: #f15e22;
    font-size: 17px;
    transition: .2s;
}

#titleFilter a {
    color: #717171;
    text-decoration: underline;
}

.css-2b097c-container {
    float: left;
    width: 235px;
    margin-left: 20px;
    margin-top: -5px;
}

.css-1pahdxg-control, .css-1pahdxg-control:hover, .css-1pahdxg-control:focus, .css-yk16xz-control:focus {
    border-color: #df0700 !important;
    box-shadow: 0 0 0 1px #df0700;
}

@media screen and (max-width: 700px) {
    .css-2b097c-container {
        margin-left: 0;
        margin-top: -15px;
        margin-bottom: 20px;
    }
    #titleMidias {
        display: none;
    }
}

.titleMidias.titleCategories li {
    font-size: 17px;
}

ul.titleCategories.titleLarger {
    display: flex;
    align-items: center;
}

.newsContent {
    width: 100%;
    padding: 20px;
}

.fromeditor a, .fromeditor a span, .fromeditor a u, .fromeditor a span {
    color: #f15e22 !important;
    text-decoration: none;
}