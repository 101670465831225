.newsBoxBigger {
    margin-bottom: 20px;
    border: 1px solid #c8c8c8;
    box-shadow: 0 0 15px rgb(0 0 0 / 20%);
    position: relative;
    background: #FFF;
    transition: .3s;
    display: flex;
    align-items: stretch;
    transition: .3s;
}

.newsBoxBigger:hover {
    box-shadow: 0 0 15px rgb(0 0 0 / 40%);
    border: 1px solid #f15e22;
}

.newsBoxBigger:hover .borderLeft {
    display: block;
    top: 10%;
    height: 75%;
}

.newsBoxBigger img {
    width: 65%;
    height: 100%;
}

.newsContentBigger {
    width: 35%;
    position: relative;
}

.newsContentBigger .infoBox {
    margin-bottom: 20px;
}

.multimidiaContent {
    position: relative;
    width: 65%;
}

/* .multimidiaContent .fromeditor {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
} */

.multimidiaContent iframe {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-color: #000;
    border: 0;
}

@media(max-width: 767px){
    .multimidiaContent {
        width: 100%;
        height: 250px;
    }
    .newsBoxBigger {
        flex-direction: column;
    }
    .newsBoxBigger img {
        width: 100%;
    }
    .newsContentBigger {
        width: 100%;
    }
}